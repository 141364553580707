import request from '@/utils/request'

const getOrderList = (params) => {
  return request({
    url: '/sell/orders',
    params,
    method: 'get'
  })
}

const getOrderDetail = (id) => {
  return request({
    url: `/sell/orders/${id}`,
    method: 'get'
  })
}

const getOrderLength = (params) => {
  return request({
    url: `/sell/orders/count`,
    params,
    method: 'get'
  })
}
const isAgree = (id, params) => {
  return request({
    url: `/mg/orders/${id}/audit`,
    data: params,
    method: 'put'
  })
}

const cancelOrder = (id, params) => {
  return request({
    url: `/sell/orders/${id}/cancel`,
    data: params,
    method: 'put'
  })
}

const isRefund = (id, params) => {
  return request({
    url: `/sell/orders/${id}/back`,
    data: params,
    method: 'put'
  })
}

const sendGoods = (id, params) => {
  return request({
    url: `/sell/orders/${id}/delivery`,
    data: params,
    method: 'put'
  })
}
const changeGoods = (id, params) => {
  return request({
    url: `/sell/orders/${id}/editLogistics`,
    data: params,
    method: 'put'
  })
}
const invoiceInfo = (id, params) => {
  return request({
    url: `/sell/orders/${id}/invoice`,
    data: params,
    method: 'put'
  })
}

const isPayment = (id) => {
  return request({
    url: `/sell/orders/${id}/payment`,
    data: { remark: '' },
    method: 'put'
  })
}

export {
  getOrderList,
  getOrderDetail, // 获取订单详情
  isAgree, // 审核订单
  cancelOrder, // 取消订单
  isRefund, // 退款审核
  sendGoods, // 发货
  invoiceInfo, // 发票
  isPayment, // 确认支付
  getOrderLength, // 订单数量
  changeGoods // 更改发货
}
